import React, { useEffect, useRef } from "react";
import "./App.css";
import {
  Button,
  Input,
  InputWrapper,
  LoadingOverlay,
  Modal,
  Progress,
  Slider,
  useMantineTheme,
  Tooltip,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import axios from "axios";
// @ts-ignore
import * as Rythm from "rythm.js";
import Konami from "react-konami-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faFastBackward,
  faFastForward,
  faPause,
  faPlay,
  faRandom,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import {
  useClipboard,
  useDocumentTitle,
  useIdle,
  useLocalStorageValue,
  useWindowEvent,
} from "@mantine/hooks";

const RYTHM = new Rythm();
let VIDEO_ELEMENT: HTMLVideoElement = document.getElementById(
  "video"
) as HTMLVideoElement;
let SONGS_LIST: any;

function App() {
  const theme = useMantineTheme();
  theme.colorScheme = "dark";

  const notifications = useNotifications();
  const clipboard = useClipboard();

  const [loaderVisible, setLoaderVisible] = React.useState(true);
  const [repeating, setRepeating] = useLocalStorageValue<string>({
    key: "repeating",
    defaultValue: "true",
  });
  const [donateModalOpened, setDonateModalOpened] = React.useState(false);

  const [song, setSong] = React.useState("");
  const [volumePercent, setVolumePercent] = useLocalStorageValue<string>({
    key: "volume",
    defaultValue: "50",
  });
  const [videoTime, setVideoTime] = React.useState(0);

  const [title, setTitle] = React.useState("Flux Industries");
  useDocumentTitle(title);

  const useDidMountEffect = (
    func: { (): void; (): void },
    deps: React.DependencyList | undefined
  ) => {
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) {
        func();
      } else {
        didMount.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
  };

  useDidMountEffect(() => {
    notifications.showNotification({
      title: "Now Playing",
      message: `Song ID: ${song}`,
      color: "violet",
    });

    document
      .getElementById("video")
      ?.setAttribute(
        "src",
        `https://cors.flux.industries/?https://videodelivery.net/${song}/downloads/default.mp4`
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);

  // Actions ran once, when the component is mounted
  useWindowEvent("load", () => {
    VIDEO_ELEMENT = document.getElementById("video") as HTMLVideoElement;

    notifications.showNotification({
      icon: "👆",
      message: "Tap anywhere to unmute",
      color: "violet",
    });

    // Get Stream URL from Cloudflare
    axios
      .get(
        "https://api.cloudflare.com/client/v4/accounts/e8ff2471cf57641f0c7b37dd48ebd7bb/stream?search=mp4",
        {
          headers: {
            Authorization: "Bearer vx9nwIqIyZ9x1aZ3M2qt1f9n-AkeWwH7oPFugqsf",
          },
        }
      )
      .then((res) => {
        SONGS_LIST = res.data.result;
        const songUID =
          res.data.result[Math.floor(Math.random() * res.data.result.length)]
            .uid;

        let queryDict: any = {};
        window.location.search
          .substring(1)
          .split("&")
          .forEach((item) => {
            queryDict[item.split("=")[0]] = item.split("=")[1];
          });
        const SONG = queryDict["song"] || songUID;
        setSong(SONG);

        RYTHM.addRythm("rythm-bass", "pulse", 0, 10, {
          min: 0.5,
          max: 1.5,
        });
        RYTHM.connectExternalAudioElement(VIDEO_ELEMENT);
        RYTHM.start();

        let videoPercentInterval: any;

        VIDEO_ELEMENT.addEventListener("loadstart", () => {
          setLoaderVisible(true);
          clearInterval(videoPercentInterval);
        });

        VIDEO_ELEMENT.addEventListener("play", () => {
          setLoaderVisible(false);
          videoPercentInterval = setInterval(() => {
            let current = VIDEO_ELEMENT.currentTime;
            let duration = VIDEO_ELEMENT.duration;
            setVideoTime((current / duration) * 100);
          });
        });

        VIDEO_ELEMENT.addEventListener("ended", () => {
          newSong();
        });
      });

    VIDEO_ELEMENT.volume = Number(volumePercent) / 100;

    // Animated title
    let spacedTitle = " Flux Industries ";
    let i = 0;

    setTimeout(() => {
      setInterval(() => {
        setTitle(
          spacedTitle.substr(i, spacedTitle.length) + spacedTitle.substr(0, i)
        );
        i = (i + 1) % title.length;
      }, 350);
    }, 1000);

    const plausibleScript = document.createElement("script");
    plausibleScript.setAttribute("data-domain", "flux.industries");
    plausibleScript.src = "https://plausible.flux.industries/js/plausible.js";
    plausibleScript.async = true;
    document.body.appendChild(plausibleScript);

    console.clear();
    console.log(
      "%cPresented by Flux Industries 🎉",
      "background: linear-gradient(to right, #50CF7F, #A277FF); font-size: 400%;"
    );
  });

  const newSong = () => {
    if (!SONGS_LIST) {
      const notification = notifications.showNotification({
        title: "Now Playing",
        message: "Fetching a new song...",
        color: "violet",
        loading: true,
        autoClose: false,
      });

      axios
        .get(
          "https://api.cloudflare.com/client/v4/accounts/e8ff2471cf57641f0c7b37dd48ebd7bb/stream?search=mp4",
          {
            headers: {
              Authorization: "Bearer vx9nwIqIyZ9x1aZ3M2qt1f9n-AkeWwH7oPFugqsf",
            },
          }
        )
        .then((res) => {
          const songUID =
            res.data.result[Math.floor(Math.random() * res.data.result.length)]
              .uid;
          setSong(songUID);
          notifications.updateNotification(notification, {
            color: "violet",
            title: "Now Playing",
            message: `Song fetched!`,
            autoClose: 3000,
          });
        });
    } else {
      const songUID =
        SONGS_LIST[Math.floor(Math.random() * SONGS_LIST.length)].uid;
      setSong(songUID);
    }
  };

  const restartSong = () => {
    notifications.showNotification({
      title: "Song",
      message: "Restarting...",
      color: "violet",
    });
    VIDEO_ELEMENT.currentTime = 0;
  };

  const togglePause = () => {
    if (VIDEO_ELEMENT.paused) {
      VIDEO_ELEMENT.play();
      notifications.showNotification({
        title: "Song",
        message: "Playback resumed.",
        color: "violet",
      });
    } else {
      notifications.showNotification({
        title: "Song",
        message: "Playback stopped.",
        color: "violet",
      });
      VIDEO_ELEMENT.pause();
    }
  };

  useEffect(() => {
    try {
      VIDEO_ELEMENT.volume = Number(volumePercent) / 100;
    } catch (_) {}
  }, [volumePercent]);

  const bitcoinAddress = "bc1qcu0aqrzsjxj0lhjskltudheq2dyfhguzes9rqt";
  const etherAddress = "0x887AF199537f56992387af30d9C8b468876D31Fd";
  const litecoinAddress = "LhZGV9CAudZzdrMYXVi2sYiMXkK5U2hUjd";
  const moneroAddress =
    "46AybH7w4yHTfxUCQtuxa8faSeBKhSzydTtuwkKaLA4RfwMYnEbv9dUeNGzaButwSTaXnEoHCwmVCXXmSxvpyST52mZtQKf";
  const bitcoinCashAddress = "qpj8my02er6q9t6u98mjruv8xh39p327s5ktrfs78m";
  const dogecoinAddress = "DKKURXWvzxjn3qnfypMreRAJFvLMSMQ1G8";
  const solanaAddress = "8SP1V75KFBGRg5reYvejBfaLQpHtyMiX6hiagojiyQDZ";
  const cardanoAddress =
    "addr1qyc2jcy8t54leya85kw95ffxfqu4xyjfw64xwyktdr9p68e3k4ussekyxdzlw52smqa2tnwq3hwt4cdy4psrzmm3lpys394v3z";
  const stellarAddress =
    "GDW6NTYIYZ6APUZRRVXVELICEK6ITRBZDPISLWBNLXSVQ43MHYB3Z76M";

  const idle = useIdle(2000, {
    events: ["mousemove", "keydown", "mousedown", "touchstart"],
  });

  useEffect(() => {
    const everything = document.querySelector("*") as HTMLElement;
    const disappearElements = document.getElementsByClassName(
      "disappear"
    ) as HTMLCollectionOf<HTMLElement>;

    if (!idle) {
      for (let i = 0; i < disappearElements.length; i++) {
        disappearElements[i].style.opacity = "1";
      }
      everything.style.cursor = "default";
    } else {
      for (let i = 0; i < disappearElements.length; i++) {
        disappearElements[i].style.opacity = "0";
      }
      everything.style.cursor = "none";
    }
  }, [idle]);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onClick={() => {
        if (VIDEO_ELEMENT.muted) {
          VIDEO_ELEMENT.muted = false;
        }
      }}
    >
      <span>
        <video
          className="fixed object-cover min-w-full min-h-full w-auto h-auto top-1/2 left-1/2 -z-10 bg-cover translate-y-[-50%] translate-x-[-50%] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0 before:content-[''] before:bg-black"
          id="video"
          autoPlay={true}
          muted={true}
          loop={repeating === "true"}
          crossOrigin="anonymous"
          typeof="application/x-mpegURL"
          disablePictureInPicture
        />
        <LoadingOverlay
          visible={loaderVisible}
          loaderProps={{ size: "xl", variant: "oval", color: "violet" }}
        />
        <Modal
          opened={donateModalOpened}
          onClose={() => {
            setDonateModalOpened(false);
          }}
          title="Donate"
        >
          <InputWrapper label="Bitcoin" className="mb-2">
            <Input
              disabled={true}
              value={bitcoinAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(bitcoinAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Bitcoin address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Ethereum" className="mb-2">
            <Input
              disabled={true}
              value={etherAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(etherAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Ethereum address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Litecoin" className="mb-2">
            <Input
              disabled={true}
              value={litecoinAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(litecoinAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Litecoin address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Bitcoin Cash" className="mb-2">
            <Input
              disabled={true}
              value={bitcoinCashAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(bitcoinCashAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Bitcoin Cash address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Dogecoin" className="mb-2">
            <Input
              disabled={true}
              value={dogecoinAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(dogecoinAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Dogecoin address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Monero" className="mb-2">
            <Input
              disabled={true}
              value={moneroAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(moneroAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Monero address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Solana" className="mb-2">
            <Input
              disabled={true}
              value={solanaAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(solanaAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Solana address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Cardano" className="mb-2">
            <Input
              disabled={true}
              value={cardanoAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(cardanoAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Cardano address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
          <InputWrapper label="Stellar" className="mb-2">
            <Input
              disabled={true}
              value={stellarAddress}
              rightSection={
                <button
                  onClick={() => {
                    clipboard.copy(stellarAddress);
                    notifications.showNotification({
                      icon: "📄",
                      title: "Stellar address",
                      message: "Copied to clibpoard.",
                      color: "violet",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              }
            />
          </InputWrapper>
        </Modal>
      </span>

      <div>
        <div className="fixed grid place-items-center h-full w-full rythm-bass">
          <h1 className="font-bold font-sans text-white select-none text-[7vw] text-animation chocolate-cookies-font main-text tracking-wider uppercase inline-block">
            Flux Industries
          </h1>
        </div>

        <div>
          <div>
            <Slider
              className="disappear fixed min-w-[20%] top-16 left-16 transition-all duration-300"
              color="violet"
              labelTransition="skew-down"
              labelTransitionDuration={150}
              labelTransitionTimingFunction="ease"
              marks={[
                { value: 0, label: "0%" },
                { value: 50, label: "50%" },
                { value: 100, label: "100%" },
              ]}
              label={(value) => `${value}%`}
              defaultValue={Number(volumePercent)}
              onChange={(value) => {
                setVolumePercent(String(value));
              }}
            />
          </div>

          <div className="grid place-items-center">
            <p className="disappear fixed top-32 text-animation place-content-center font-bold select-none sm:text-sm lg:text-base">
              Current Song:{" "}
              <span className="select-text">{song ? song : "Loading..."}</span>
            </p>
          </div>

          <div className="fixed right-16 top-16">
            <Button
              className="disappear mx-1 bg-violet-800 transition-all duration-300 hover:drop-shadow-2xl hover:drop-shadow-purple-500"
              onClick={restartSong}
              color="violet"
            >
              <FontAwesomeIcon icon={faFastBackward} />
            </Button>
            <Button
              className="disappear mx-1 bg-violet-800 transition-all duration-300 hover:drop-shadow-2xl hover:drop-shadow-purple-500"
              onClick={togglePause}
              color="violet"
            >
              <FontAwesomeIcon
                icon={VIDEO_ELEMENT?.paused ? faPlay : faPause}
              />
            </Button>
            <Button
              className="disappear mx-1 bg-violet-800 transition-all duration-300 hover:drop-shadow-2xl hover:drop-shadow-purple-500"
              onClick={newSong}
              color="violet"
            >
              <FontAwesomeIcon icon={faFastForward} />
            </Button>
          </div>

          <div className="fixed right-16 bottom-16">
            <Tooltip
              label={repeating === "true" ? "Repeating" : "Shuffle"}
              transition="fade"
              position="top"
              color="violet"
              withArrow
            >
              <Button
                className="disappear bg-violet-800 transition-all duration-300 hover:drop-shadow-2xl hover:drop-shadow-purple-500"
                onClick={() => {
                  setRepeating(repeating === "true" ? "false" : "true");
                  notifications.showNotification({
                    icon: "🔁",
                    title: "Repeating",
                    message: `Repeating is now ${
                      repeating === "false" ? "on" : "off"
                    }.`,
                    color: "violet",
                  });
                }}
                color="violet"
              >
                {repeating === "true" ? (
                  <FontAwesomeIcon icon={faSync} />
                ) : (
                  <FontAwesomeIcon icon={faRandom} />
                )}
              </Button>
            </Tooltip>
          </div>

          <div>
            <Button
              className="disappear fixed left-16 bottom-16 bg-violet-800 transition-all duration-300 hover:drop-shadow-2xl hover:drop-shadow-purple-500"
              onClick={() => {
                setDonateModalOpened(true);
              }}
              color="violet"
            >
              Donate
            </Button>
          </div>
        </div>
      </div>

      <Konami
        action={() => {
          setSong("82211f9ebf8aca58b28e0ea82096b747");
          notifications.showNotification({
            title: "You know the rules, and so do I.",
            message: "Cheating is not allowed.",
            color: "violet",
          });
        }}
      />

      <Progress
        className="fixed bottom-0 min-w-full"
        striped={true}
        radius={0}
        color="violet"
        sx={() => ({
          background:
            "linear-gradient(60deg, #82e2ff, #a277ff, #61ffca, #ffca85, #f694ff, #82e2ff)",
        })}
        value={videoTime}
      />
    </div>
  );
}

export default App;
